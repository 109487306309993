$red: #d32d26;

body {
  --adm-radius-s: 4px;
  --adm-radius-m: 8px;
  --adm-radius-l: 12px;
  --adm-font-size-1: 9px;
  --adm-font-size-2: 10px;
  --adm-font-size-3: 11px;
  --adm-font-size-4: 12px;
  --adm-font-size-5: 13px;
  --adm-font-size-6: 14px;
  --adm-font-size-7: 15px;
  --adm-font-size-8: 16px;
  --adm-font-size-9: 17px;
  --adm-font-size-10: 18px;
  --adm-color-primary: #1677ff;
  --adm-color-success: #00b578;
  --adm-color-warning: #ff8f1f;
  --adm-color-danger: #ff3141;
  --adm-color-yellow: #ff9f18;
  --adm-color-orange: #ff6430;
  --adm-color-wathet: #e7f1ff;
  --adm-color-text: #333333;
  --adm-color-text-secondary: #666666;
  --adm-color-weak: #999999;
  --adm-color-light: #cccccc;
  --adm-color-border: #eeeeee;
  --adm-color-background: #ffffff;
  --adm-color-highlight: var(--adm-color-danger);
  --adm-color-white: #ffffff;
  --adm-color-box: #f5f5f5;
  --adm-color-text-light-solid: var(--adm-color-white);
  --adm-color-text-dark-solid: #000000;
  --adm-color-fill-content: var(--adm-color-box);
  --adm-font-size-main: var(--adm-font-size-5);
  --adm-font-family: roboto, "PingFang SC", "miui", "Hiragino Sans GB",
    "Microsoft Yahei", sans-serif;
  --adm-border-color: var(--adm-color-border);
}

body,
html {
  margin: 0;
  background-color: #000;
  font-family: Roboto, sans-serif;
  font-weight: 400;
}

.banner {
  // background-image: linear-gradient(180deg, #e60012 0, #ca0000 100%);
  background-color: #e60012 !important;
  background-image: url(../public/assets/checkerboard-transparent.png) !important;
  color: #fff;
  padding: 14px 11px;
  position: sticky;
  top: var(--banner-offset);
  z-index: 55;
}
// .banner::before {
//   // background-image: url(https://mariokart8.nintendo.com/assets/img/bgs/checkerboard-transparent.png);
//   background-image: url(../public/assets/checkerboard-transparent.png);
//   content: "";
//   position: absolute;
//   inset: 0;
//   width: 100%;
//   opacity: 0.5;
// }

.tires-background {
  background-image: url(../public/assets/tires.jpg) !important;
}
.checker-background {
  background-image: url(../public/assets/checkerboard-white.jpg) !important;
}
.checker-black-background {
  background-image: url(../public/assets/checkerboard-black.jpg) !important;
}
.adm-list-item {
  background: none;
}

.bounce-1 {
  animation-name: bounce-1;
  animation-timing-function: linear;
}
@keyframes bounce-1 {
  0% {
    transform: translateX(100px) translateY(0px);
  }
  25% {
    transform: translateX(-100px) translateY(0px);
  }
  50% {
    transform: translateX(-100px) translateY(175px);
  }
  75% {
    transform: translateX(100px) translateY(175px);
  }
  100% {
    transform: translateX(100px) translateY(0px);
  }
}
.bounce-2 {
  animation-name: bounce-2;
  animation-timing-function: linear;
}
@keyframes bounce-2 {
  0% {
    transform: translateX(100px) translateY(0px);
  }
  25% {
    transform: translateX(100px) translateY(-200px);
  }
  50% {
    transform: translateX(-100px) translateY(-200px);
  }
  75% {
    transform: translateX(-100px) translateY(0px);
  }
  100% {
    transform: translateX(100px) translateY(0px);
  }
}
.animate-1 {
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

.adm-result-page-bg {
  background-image: url(../public/assets/checkerboard-black.jpg) !important;
}

.checker-blue-background-inner {
  background-color: #0c00b4 !important;
  background-image: url(../public/assets/checkerboard-transparent.png) !important;
  color: white;
}

.adm-result-page-footer {
  background-image: linear-gradient(180deg, #1f0103 0, #040000 100%);
}
.adm-result-page-footer::before {
  background-image: url(https://mariokart8.nintendo.com/assets/img/bgs/checkerboard-transparent.png);
  // background: none;
  content: "";
  position: absolute;
  inset: 0;
  width: 100%;
  opacity: 0.5;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.box-wrapper {
  /* padding-top: 10px; */
  width: 100%;
  height: 80px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}

.main-element {
  position: absolute;
  top: 1%;
  left: 1%;
  display: block;
  height: 90%;
  width: 90%;
  margin: auto;
  align-self: center;
  background: #fff;
  z-index: 1;
}

.border {
  position: absolute;
  display: block;
  top: -50%;
  left: -50%;
  z-index: -9;
  display: block;
  height: 200%;
  width: 200%;
  /* transform: rotate(-45deg); */
  overflow: hidden;
  background: linear-gradient(
    to right,
    #fff 20%,
    #fff 40%,
    #ecd08c 50%,
    #ecd08c 75%,
    /* #fff 80%, */ #fff 100%
  );
  background-size: 200% auto;

  animation: shine 3s linear infinite;
}

@keyframes shine {
  to {
    background-position: 200% center;
    transform: rotate(360deg) scale(1);
  }

  from {
    transform: rotate(0deg) scale(1);
    background-position: 0% center;
  }
}

@keyframes TransitioningBackground {
  0% {
    background-position: 41% 20%;
  }
  50% {
    background-position: 99% 50%;
  }
  100% {
    background-position: 41% 20%;
  }
}

@keyframes Transitioning {
  0% {
    transform: translateX(-100px) skewX(-15deg);
  }

  100% {
    transform: translateX(200px) skewX(-15deg);
  }
}

.subscribe-button {
  font-size: 0.8rem;
  font-weight: 600;
  color: white;
  text-align: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  // for shine & gradient effects to work
  position: relative;
  overflow: hidden;
  // for background color shift
  background-image: (linear-gradient(270deg, #8e9ac2, #42579a));
  background-size: 400% 400%;
  animation: TransitioningBackground 10s ease infinite;
  // to ease the button growth on hover
  transition: 0.6s;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(110, 80, 20, 0.4),
    inset 0 -2px 5px 1px rgba(139, 66, 8, 1),
    inset 0 -1px 1px 3px rgba(250, 227, 133, 1);
  background-image: linear-gradient(
    160deg,
    #a54e07,
    #b47e11,
    #fef1a2,
    #bc881b,
    #a54e07
  );
  border: 1px solid #a55d07;
  color: rgb(100, 20, 5);
  text-shadow: 0 2px 2px rgba(250, 227, 133, 1);

  // psuedo-element shine animation left side
  &::before {
    content: "";
    display: block;
    position: absolute;
    background: rgba(255, 255, 255, 0.5);
    width: 60px;
    height: 100%;
    top: 0;
    filter: blur(30px);
    transform: translateX(-100px) skewX(-15deg);
    animation: Transitioning 4s ease infinite;
  }

  // psuedo-element shine animation right side
  &::after {
    content: "";
    display: block;
    position: absolute;
    background: rgba(255, 255, 255, 0.2);
    width: 30px;
    height: 100%;
    top: 0;
    filter: blur(5px);
    transform: translateX(-100px) skewX(-15deg);
    animation: Transitioning 4s ease infinite;
  }
}

.stickToBottom {
  width: "100%";
  position: "fixed";
  bottom: 0;
}
